<template>
  <div class="BillingDetailsAdd">
    <div v-if="showAddBtn()" class="add-new-details-btn-container">
      <md-button @click="showDialog()" class="md-primary md-raised add-new-details-btn">
        <i class="material-icons">add</i>
        <span>{{ $t('common.actions.addNewEntry') }}</span>
      </md-button>
    </div>

    <md-card v-else-if="isBillingDetailsAddState" class="card-billing-details-add">
      <md-card-header>
        <md-card-header-text>
          <div class="md-title">{{ $t('billingDetailsAdd.title') }}</div>
          <span class="o-default-body">{{ $t('billingDetailsAdd.description') }}</span>
        </md-card-header-text>
      </md-card-header>

      <Loader v-if="isLoading" />

      <md-card-content v-else>
        <BillingDetailsForm />
      </md-card-content>
    </md-card>

    <md-dialog :md-active.sync="showBillingDetailsAddDialog">
      <md-dialog-title>{{ $t('common.actions.addBillingDetails') }}</md-dialog-title>
      <div class="o-dialog-buttons">
        <md-button @click="addNewDetails()" class="md-primary md-raised add-new-details-btn">
          <i class="material-icons">add</i>
          <span>{{ $t('common.actions.addNewEntry') }}</span>
        </md-button>
      </div>
      <div v-if="userDetailsAddresses && userDetailsAddresses.length">
        <div class="o-default-h4 u-text-center">{{ $t('billingDetailsAdd.useExistingEntry') }}</div>
        <BillingDetailsExistingEntries />
      </div>
      <md-dialog-actions>
        <md-button class="md-primary" @click="hideDialog()">{{ $t('common.actions.close') }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import store from '@/store';
import Helpers from '@/common/helpers.js';
import Loader from '@/components/util/Loader';
import BillingDetailsForm from '@/components/billing-details/BillingDetailsForm.vue';
import BillingDetailsExistingEntries from '@/components/billing-details/BillingDetailsExistingEntries.vue';
import {
  FETCH_USER_DETAILS_ALL,
  SHOW_BILLING_DETAILS_ADD_DIALOG,
  RESET_BILLING_DETAILS,
  SET_BILLING_DETAILS_ADD_STATE_FLAG
} from '@/store/actions.type';

export default {
  name: 'BillingDetailsAdd',
  components: {
    Loader,
    BillingDetailsForm,
    BillingDetailsExistingEntries
  },
  data() {
    return {
      editState: false,
      isLoading: false
    };
  },
  computed: {
    ...mapGetters([
      'userDetailsAddresses',
      'isBillingDetailsAddState',
      'isBillingDetailsEditState',
      'isBillingDetailsOtherEditState'
    ]),
    showBillingDetailsAddDialog: {
      get: () => store.getters.showBillingDetailsAddDialog,
      set: (flag) => store.dispatch(SHOW_BILLING_DETAILS_ADD_DIALOG, flag)
    }
  },
  created() {
    this.isLoading = true;
    this.$store.dispatch(FETCH_USER_DETAILS_ALL)
      .finally(() => {
        this.isLoading = false;
        this.$store.dispatch(RESET_BILLING_DETAILS)
      });
  },
  methods: {
    showAddBtn() {
      return (this.isBillingDetailsAddState || this.isBillingDetailsEditState || this.isBillingDetailsOtherEditState) ? false : true;
    },
    showDialog() {
      this.isLoading = true;
      this.$store.dispatch(RESET_BILLING_DETAILS)
        .then(() => {
          this.$store.dispatch(SET_BILLING_DETAILS_ADD_STATE_FLAG, false)
            .then(() => {
              this.$store.dispatch(SHOW_BILLING_DETAILS_ADD_DIALOG, true)
            });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    hideDialog() {
      this.$store.dispatch(SHOW_BILLING_DETAILS_ADD_DIALOG, false);
    },
    addNewDetails() {
      this.isLoading = true;
      this.$store.dispatch(RESET_BILLING_DETAILS)
        .then(() => {
          this.$store.dispatch(RESET_BILLING_DETAILS)
          this.$store.dispatch(SET_BILLING_DETAILS_ADD_STATE_FLAG, true)
            .then(() => {
              this.$store.dispatch(SHOW_BILLING_DETAILS_ADD_DIALOG, false)
                .then(() => {
                  Helpers.scrollIntoView('BillingDetailsAdd');
                });
            });
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
}
</script>

<style lang="scss">
.BillingDetailsAdd {

  .add-new-details-btn {

    .md-button-content {
      display: flex;
    }
  }
}

.md-dialog {

  .BillingDetailsExistingEntries {
    margin-top: .8rem;
    max-height: 24rem;
    overflow-y: auto;
    padding: 0;
    @include tablet {
      margin-top: 1.6rem;
      max-height: 40rem;
    }
  }

  .o-dialog-buttons {

    .add-new-details-btn {
      @include flex-center-xy;
      height: 4rem;
      margin: 2.4rem auto;
      min-width: 24rem;
      width: 100%;
      @include desktop { width: auto; }

      .md-button-content {
        display: flex;
      }

      i, span {
        line-height: 4rem;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.BillingDetailsAdd {

  .add-new-details-btn-container {
    text-align: left;
  }

  .add-new-details-btn {
    @include flex-center-xy;
    height: 4rem;
    margin: 2.4rem 0;
    min-width: 24rem;
    width: 100%;
    @include desktop { width: auto; }

    i, span {
      line-height: 4rem;
    }
  }

  .card-billing-details-add {
    margin: 1.6rem 0;
  }
}
</style>
