<template>
  <div class="BillingDetailsItem">
    <div class="billing-details-item__content">
      <div class="o-default-h5">{{ billingDetailsItem.registrantName }} - {{ Helpers.showEntityType(billingDetailsItem.personType) }}</div>
      <div v-if="billingDetailsItem.cnp" class="o-default-body">
        {{ $t('common.addressLabels.identificationNumber') }}: {{ billingDetailsItem.cnp }}
      </div>
      <div v-if="billingDetailsItem.registrationNumber" class="o-default-body">
        {{ $t('common.addressLabels.referenceNumber') }}: {{ billingDetailsItem.registrationNumber }}
      </div>
      <div v-if="billingDetailsItem.address1">
        <span class="o-default-body">{{ $t('common.addressLabels.address') }}: {{ billingDetailsItem.address1 }}</span>
        <span v-if="billingDetailsItem.address2" class="o-default-body">, {{ billingDetailsItem.address2 }}</span>
        <span v-if="billingDetailsItem.city" class="o-default-body">, {{ billingDetailsItem.city }}</span>
        <span v-if="billingDetailsItem.state" class="o-default-body">, {{ billingDetailsItem.state }}</span>
        <span v-if="billingDetailsItem.country" class="o-default-body">, {{ billingDetailsItem.country }}</span>
      </div>
      <div v-if="billingDetailsItem.email" class="o-default-body">
        {{ $t('common.addressLabels.email') }}: {{ billingDetailsItem.email }}
      </div>
      <div v-if="billingDetailsItem.phone" class="o-default-body">
        {{ $t('common.addressLabels.phone') }}: {{ billingDetailsItem.phone }}
      </div>
    </div> <!-- /.billing-details-item__content -->
    <div class="billing-details-item__actions">
      <md-button @click.stop="openConfirmActionDialog($t('common.confirmation.setAsDefault'), changeDefaultBillingDetails, billingDetailsItem.id)"
                 class="md-dense md-raised md-primary">
        {{ $t('billingDetailsItem.setAsDefault') }}
        <md-tooltip md-direction="bottom">{{ $t('billingDetailsItem.setAsDefaultTooltip') }}</md-tooltip>
      </md-button>
      <div class="billing-details-item__icon-btns">
        <md-button @click.stop="editBillingDetailsOther(billingDetailsItem)"
                   class="md-icon-button">
          <md-icon>edit</md-icon>
          <md-tooltip md-direction="bottom">{{ $t('common.actions.editData') }}</md-tooltip>
        </md-button>
        <md-button @click.stop="openConfirmActionDialog($t('common.confirmation.delete'), deleteBillingDetails, billingDetailsItem.id)"
                   class="md-icon-button">
          <md-icon>delete</md-icon>
          <md-tooltip md-direction="bottom">{{ $t('common.actions.deleteData') }}</md-tooltip>
        </md-button>
      </div>
    </div> <!-- /.billing-details-item__actions -->

    <md-dialog :md-active.sync="confirmAction.showDialog" class="o-dialog-confirmation">
      <md-dialog-title>{{ $t('common.confirmation.pleaseConfirm') }}</md-dialog-title>
      <div class="o-dialog-content">
        <div class="o-default-body">{{ confirmAction.message }}</div>
      </div>
      <md-dialog-actions>
        <md-button class="md-raised" @click="confirmAction.showDialog = false">{{ $t('common.actions.cancel') }}</md-button>
        <md-button class="md-raised md-primary u-light-green-background" @click="handleFunctionCall(confirmActionFooParam)">{{ $t('common.actions.confirm') }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div> <!-- /.BillingDetailsItem -->
</template>

<script>
import Helpers from '@/common/helpers.js';
import LocalStorageService from '@/common/localstorage.service';
import {
  POST_BILLING_DETAILS_SET_DEFAULT,
  POST_BILLING_DETAILS_REMOVE,
  SET_BILLING_DETAILS_OTHER_EDIT_STATE_FLAG,
  UPDATE_BILLING_DETAILS
} from '@/store/actions.type';
import {
  SET_RESET_BILLING_DETAILS_ERROR
} from '@/store/mutations.type';

export default {
  name: 'BillingDetailsItem',
  props: {
    billingDetailsItem: {
      type: Object,
      required: false
    },
  },
  data() {
    return {
      confirmAction: {
        showDialog: false,
        message: ''
      },
      handleFunctionCall: null,
    }
  },
  methods: {
    openConfirmActionDialog(msg, foo, fooParam) {
      this.confirmAction.message = msg;
      this.handleFunctionCall = foo;
      this.confirmActionFooParam = fooParam
      this.confirmAction.showDialog = true;
    },
    changeDefaultBillingDetails(id) {
      this.$store.dispatch(POST_BILLING_DETAILS_SET_DEFAULT, id)
        .then(() => {
          LocalStorageService.setSessionStorage('isUpdateSuccess', 'true');
          location.reload();
        });
    },
    editBillingDetailsOther(details) {
      this.showDialog = false;
      this.isLoading = true;
      this.$store.dispatch(UPDATE_BILLING_DETAILS, details)
        .then(() => {
          this.$store.dispatch(SET_BILLING_DETAILS_OTHER_EDIT_STATE_FLAG, true)
            .then(() => {
              this.$store.commit(SET_RESET_BILLING_DETAILS_ERROR);
              Helpers.scrollIntoView('BillingDetailsOther');
            })
            .finally(() => {
              this.isLoading = false;
            });
        });
    },
    deleteBillingDetails(id) {
      this.$store.dispatch(POST_BILLING_DETAILS_REMOVE, id)
        .then(() => {
          LocalStorageService.setSessionStorage('isUpdateSuccess', 'true');
          location.reload();
        });
    }
  }
}
</script>

<style lang="scss" scoped>
.BillingDetailsItem {
  display: flex;
  flex-wrap: wrap;
  padding: 1.6rem 0;
  width: 100%;

  .o-default-h5 {
    margin-bottom: .4rem;
  }

  .billing-details-item__content {
    flex: 0 0 100%;
    @include tablet { flex: 1; }
  }

  .billing-details-item__actions {
    @include flex-center-xy;
    flex-wrap: wrap;
    justify-content: center;
    margin: 1.6rem -.8rem 0;
    @include tablet { justify-content: flex-end; margin: 0; }
  }

  .billing-details-item__icon-btns {
    display: inline-block;
  }
}
</style>
