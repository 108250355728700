<template>
  <div class="BillingDetailsOther">
    <md-card class="card-billing-details-other">
      <md-card-header>
        <md-card-header-text>
          <div class="md-title">{{ $t('billingDetailsOther.title') }}</div>
          <div class="o-default-body">{{ $t('billingDetailsOther.description') }}</div>
        </md-card-header-text>
      </md-card-header>

      <Loader v-if="isLoading" />
      <md-card-content v-else-if="isBillingDetailsOtherEditState">
        <BillingDetailsForm />
      </md-card-content>

      <md-card-content v-else>
        <md-list>
          <md-list-item v-for="item in billingDetailsNonDefault"
                        :key="item.id"
                        @click="showBillingDetailsItemDialog(item)"
                        class="with-divider">
            <BillingDetailsItem :billingDetailsItem="item" />
          </md-list-item>
        </md-list>
      </md-card-content>
    </md-card>

    <md-dialog :md-active.sync="confirmAction.showDialog" class="o-dialog-confirmation">
      <md-dialog-title>{{ $t('common.confirmation.pleaseConfirm') }}</md-dialog-title>
      <div class="o-dialog-content">
        <div class="o-default-body">{{ confirmAction.message }}</div>
      </div>
      <md-dialog-actions>
        <md-button class="md-raised" @click="confirmAction.showDialog = false">{{ $t('common.actions.cancel') }}</md-button>
        <md-button class="md-raised md-primary u-light-green-background" @click="handleFunctionCall(confirmActionFooParam)">{{ $t('common.actions.confirm') }}</md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog v-if="billingDetailsItem" :md-active.sync="showDialog">
      <md-dialog-title>{{ $t('billingDetailsOther.editModalTitle') }}</md-dialog-title>
      <div class="o-dialog-content">
        <div class="o-default-h5">{{ billingDetailsItem.registrantName }} - {{ Helpers.showEntityType(billingDetailsItem.personType) }}</div>
        <div v-if="billingDetailsItem.cnp" class="o-default-body">
          {{ $t('common.addressLabels.identificationNumber') }}: {{ billingDetailsItem.cnp }}
        </div>
        <div v-if="billingDetailsItem.registrationNumber" class="o-default-body">
          {{ $t('common.addressLabels.referenceNumber') }}: {{ billingDetailsItem.registrationNumber }}
        </div>
        <div v-if="billingDetailsItem.address1">
          <span class="o-default-body">{{ $t('common.addressLabels.address') }}: {{ billingDetailsItem.address1 }}</span>
          <span v-if="billingDetailsItem.address2" class="o-default-body">, {{ billingDetailsItem.address2 }}</span>
          <span v-if="billingDetailsItem.city" class="o-default-body">, {{ billingDetailsItem.city }}</span>
          <span v-if="billingDetailsItem.state" class="o-default-body">, {{ billingDetailsItem.state }}</span>
          <span v-if="billingDetailsItem.country" class="o-default-body">, {{ billingDetailsItem.country }}</span>
        </div>
        <div v-if="billingDetailsItem.email" class="o-default-body">
          {{ $t('common.addressLabels.email') }}: {{ billingDetailsItem.email }}
        </div>
        <div v-if="billingDetailsItem.phone" class="o-default-body">
          {{ $t('common.addressLabels.phone') }}: {{ billingDetailsItem.phone }}
        </div>
      </div>
      <div class="o-dialog-buttons">
        <md-button @click.stop="openConfirmActionDialog($t('common.confirmation.setAsDefault'), changeDefaultBillingDetails, billingDetailsItem.id)"
                   class="md-raised md-primary">
          {{ $t('billingDetailsOther.setAsDefault') }}
        </md-button>
        <md-button @click.stop="editBillingDetailsOther(billingDetailsItem)"
                   class="md-raised md-primary">
          {{ $t('common.actions.editData') }}
        </md-button>
        <md-button @click.stop="openConfirmActionDialog($t('common.confirmation.delete'), deleteBillingDetails, billingDetailsItem.id)"
                   class="md-raised md-accent md-primary">
          {{ $t('common.actions.deleteData') }}
        </md-button>
      </div>
      <md-dialog-actions>
        <md-button class="md-primary" @click="showDialog = false">{{ $t('common.actions.close') }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div> <!-- /.BillingDetailsOther -->
</template>

<script>
import { mapGetters } from 'vuex';
import Helpers from '@/common/helpers.js';
import Loader from '@/components/util/Loader';
import LocalStorageService from '@/common/localstorage.service';
import BillingDetailsForm from '@/components/billing-details/BillingDetailsForm.vue';
import BillingDetailsItem from '@/components/billing-details/BillingDetailsItem';
import {
  POST_BILLING_DETAILS_SET_DEFAULT,
  POST_BILLING_DETAILS_REMOVE,
  SET_BILLING_DETAILS_OTHER_EDIT_STATE_FLAG,
  UPDATE_BILLING_DETAILS
} from '@/store/actions.type';

export default {
  name: 'BillingDetailsOther',
  props: {
    billingDetailsNonDefault: {
      type: Array,
      required: false
    }
  },
  components: {
    BillingDetailsForm,
    BillingDetailsItem,
    Loader
  },
  computed: {
    ...mapGetters(['isBillingDetailsOtherEditState'])
  },
  data() {
    return {
      billingDetailsItem: null,
      confirmAction: {
        showDialog: false,
        message: ''
      },
      showDialog: false,
      handleFunctionCall: null,
      isLoading: false
    }
  },
  methods: {
    openConfirmActionDialog(msg, foo, fooParam) {
      this.confirmAction.message = msg;
      this.handleFunctionCall = foo;
      this.confirmActionFooParam = fooParam
      this.showDialog = false;
      this.confirmAction.showDialog = true;
    },
    showBillingDetailsItemDialog(billingDetailsItem) {
      this.billingDetailsItem = { ...billingDetailsItem }
      this.showDialog = true;
    },
    changeDefaultBillingDetails(id) {
      this.$store.dispatch(POST_BILLING_DETAILS_SET_DEFAULT, id)
        .then(() => {
          LocalStorageService.setSessionStorage('isUpdateSuccess', 'true');
          location.reload();
        });
    },
    editBillingDetailsOther(item) {
      this.showDialog = false;
      this.isLoading = true;
      this.$store.dispatch(UPDATE_BILLING_DETAILS, item)
        .then(() => {
          this.$store.dispatch(SET_BILLING_DETAILS_OTHER_EDIT_STATE_FLAG, true)
            .then(() => {
              Helpers.scrollIntoView('BillingDetailsOther');
            })
            .finally(() => {
              this.isLoading = false;
            });
        });
    },
    deleteBillingDetails(id) {
      this.$store.dispatch(POST_BILLING_DETAILS_REMOVE, id)
        .then(() => {
          LocalStorageService.setSessionStorage('isUpdateSuccess', 'true');
          location.reload();
        });
    }
  }
}
</script>

<style lang="scss">
.BillingDetailsOther {

  .md-list-item-content {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>

<style lang="scss" scoped>
.BillingDetailsOther {

  .md-list {
    padding: 0 !important;
  }

  .card-billing-details-other {
    margin: 1.6rem 0;
  }

  .with-divider {
    border-bottom: $palette-secondary-color-three .1rem solid;

    &:last-of-type {
      border-bottom: none;
    }
  }
}
</style>
