<template>
  <div class="BillingDetailsView">
    <Loader v-if="isBillingDetailsLoading" />
    <div v-else-if="this.billingDetailsAddresses && this.billingDetailsAddresses.length">
      <BillingDetailsSummary v-if="billingDetailsDefault" :billingDetailsDefault="billingDetailsDefault" />
      <BillingDetailsOther v-if="billingDetailsNonDefault" :billingDetailsNonDefault="billingDetailsNonDefault" />
      <BillingDetailsAdd />
    </div>
    <BillingDetailsEmpty v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import store from '@/store';
import Loader from '@/components/util/Loader';
import BillingDetailsSummary from '@/components/billing-details/BillingDetailsSummary';
import BillingDetailsOther from '@/components/billing-details/BillingDetailsOther';
import BillingDetailsAdd from '@/components/billing-details/BillingDetailsAdd';
import BillingDetailsEmpty from '@/components/billing-details/BillingDetailsEmpty';
import {
  FETCH_BILLING_DETAILS_ALL,
  RESET_BILLING_DETAILS_MODULE
} from '@/store/actions.type';
import {
  SET_BILLING_DETAILS_LOADING
} from '@/store/mutations.type';

export default {
  name: 'BillingDetailsView',
  components: {
    Loader,
    BillingDetailsSummary,
    BillingDetailsOther,
    BillingDetailsAdd,
    BillingDetailsEmpty
  },
  async beforeRouteLeave(to, from, next) {
    await store.dispatch(RESET_BILLING_DETAILS_MODULE);
    next();
  },
  computed: {
    ...mapGetters([
      'isBillingDetailsLoading',
      'billingDetailsAddresses',
      'billingDetailsDefault',
      'billingDetailsNonDefault'
    ])
  },
  created() {
    this.$store.commit(SET_BILLING_DETAILS_LOADING, true);
    this.$store.dispatch(FETCH_BILLING_DETAILS_ALL)
      .finally(() => {
        this.$store.commit(SET_BILLING_DETAILS_LOADING, false);
      });
  }
};
</script>
